
import axios from 'axios'
import Vue from 'vue'
import url from './url'
 
 console.log(':',url )
const instance = axios.create({
  
    baseURL: url,
    timeout: 10000
});
instance.interceptors.request.use(config => {
    if (config.url !== '/api/admin/login') {
        const token = sessionStorage.getItem('ExinToken');
        if (token) {
            config.headers['Authorization'] = token;
        }
    }
    return config;
}, err => {
    return Promise.reject(err)
});
instance.interceptors.response.use(config => {
    if (config.status == 200) {
    }
    return config
}, err => {
    Vue.prototype.$Message.error(err.response.data.msg)
    return Promise.reject(err.response.data);
})
export default instance;

