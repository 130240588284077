import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import apiPlugin from '@/http/api' // api
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import LoadingMixin from './utils/loading'; // loading 
import utils from './utils/index' //自定义指令
import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
Vue.use(Viewer,{
  defaultOptions: {
    zIndex: 9999
  }
});
Vue.mixin(LoadingMixin);
Vue.prototype.$utils = utils
Vue.use(ViewUI);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(apiPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
