
import Vue from 'vue'
export default {
   
    // base64 生成file文件
    base64toFile(data, name = 'quanyi') {
        const fileName = `${new Date().getTime()}`;
        const dataArr = data.split(",");
        const byteString = atob(dataArr[1]);
        const options = {
            type: "image/jpeg",
            endings: "native",
        };
        const u8Arr = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i);
        }
        let formData = new FormData();
        formData.append("file", new File([u8Arr], fileName + ".jpg", options));
        formData.append("name", name);
        return formData;
    },
    // 上传图片
    uploadImg(file) {
        return Vue.prototype.$api.setting.uploadImg(file)
    },
    // query 参数解密
    secretKey(data) {
        const decryptedData = Vue.prototype.$crypto.AES.decrypt(data, 'YourSecretKey').toString(Vue.prototype.$crypto.enc.Utf8);
        return decryptedData
    },
    // 导出excel
    async exportToExcel(title, data) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
        //添加表头
        worksheet.addRow([...title]);
        // 添加数据
        worksheet.addRow(data);
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'example.xlsx';

        a.click();


        window.URL.revokeObjectURL(url);
    }
}

