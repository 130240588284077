<template>
  <div class="customerList" style="overflow-y: scroll;">

    <div>
      <div style="display: flex;align-items: center;">
        <span>门店：</span>
        <Select v-model="customer.store_id" style="width: 200px" @on-change="store" filterable>
          <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <div style="display: flex;align-items: center;">
          <div style="margin-left: 30px;">关键字搜索：</div>
          <Input search enter-button placeholder="搜索关键字..." style="width: 300px" @on-search="search($event)" />
        </div>

      </div>

      <div style="margin-right: 20px;">
        <Button type="primary" @click="exportToExcel">导出Excel表</Button>
      </div>
    </div>

    <div style="margin-bottom: 20px;">
      <Button type="primary" @click="addclient('添加伊芯客户', '')">添加客户</Button>

    </div>
    <div>
      <Table style="min-width: none;" :columns="columns1" :data="data.data"></Table>

    </div>
    <div class="pages">
      <div>
      </div>
      <div>
        <Page show-total  :total="data.total" :page-size="customer.limit" @on-change="pageChange" />
      </div>

    </div>
    <Modal v-model="ModalStatus" :title="Modalname" @on-ok="cancel(true, Modalname)" @on-cancel="cancel(false)"
      width="800">
      <div class="form">
        <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80" label-position="left">
          <FormItem label="姓名" prop="name">
            <Input type="text" v-model="formCustom.name"></Input>
          </FormItem>
          <FormItem label="电话" prop="phone">
            <Input type="number" v-model="formCustom.phone"></Input>
          </FormItem>
          <FormItem label="部门" prop="storeId">
            <Select v-model="formCustom.storeId" :label-in-value="true" @on-change="Select">
              <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
          <FormItem label="订单编号" prop="orderNum">
            <Input type="text" v-model="formCustom.orderNum"></Input>
          </FormItem>
          <FormItem label="订单金额" prop="orderAmount">
            <Input type="number"  v-model="formCustom.orderAmount"></Input>
          </FormItem>
          <!-- <FormItem>
            <Button type="primary" @click="handleSubmit('formCustom')">Submit</Button>
            <Button @click="handleReset('formCustom')" style="margin-left: 8px">Reset</Button>
          </FormItem> -->
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import ExcelJS from 'exceljs';
export default {
  name: 'DomeHomeView',
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入姓名'));
      } else {
        callback();
      }
    };
    const phone = (rule, value, callback) => {
      // 使用正则表达式检查手机号格式
      const phoneRegex = /^1[3-9]\d{9}$/; // 这个正则表达式匹配中国大陆的手机号格式

      if (!value) {
        return callback(new Error('请输入电话'));
      } else if (!phoneRegex.test(value)) {
        return callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };


    const storeName = (rule, value, callback) => {
      if (value === null || value === '') {
        return callback(new Error('请选择部门'));
      } else {
        callback();
      }
    };

    const validatePassCheck = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入订单编号'));
      } else {
        callback();
      }
    };
    const validateAge = (rule, value, callback) => {
      
      console.log('value, callback:',value, callback)
      if (!value) {
        return callback(new Error('请输入订单金额'));
      } else {
        callback();
      }

    };
    return {
      Modalname: '添加伊芯客户',
      editId: 0,
      customer: {
        store_id: 0, //门店id
        searchValue: '', //搜索
        limit: 12, //页条数
        page: 1, //页
      },
      formCustom: {
        name: '', //姓名
        phone: '', // 电话
        storeId: '',//部门id
        storeName: '',//部门名称
        orderNum: '',//订单编号
        orderAmount: '',//订单金额
      },
      ruleCustom: {
        name: [
          { validator: validatePass, trigger: 'blur' }
        ],
        phone: [
          { validator: phone, trigger: 'blur' }
        ],

        storeId: [
          { validator: storeName, trigger: 'blur' }
        ],
        orderNum: [
          { validator: validatePassCheck, trigger: 'change' }
        ],
        orderAmount: [
          { validator: validateAge, trigger: 'blur' }
        ]
      },
      model1: '',
      ModalStatus: false,
      cityList: [
      ],
      columns1: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          fixed: 'left',
          width: '60'
        },
        {
          title: '客户姓名',
          align: 'center',
          key: 'name'
        },
        {
          title: '电话',
          align: 'center',


          key: 'phone'
        },
        {
          title: '门店',
          align: 'center',

          key: 'store_name'
        },
        {
          title: '订单编号',
          align: 'center',

          key: 'order_num'
        },
        {
          title: '定单金额',
          align: 'center',

          key: 'order_amount'
        },
        {
          title: '操作',
          key: 'address',
          fixed: 'right',
          width: '150',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('span', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color: '#1e7fea',
                  cursor: 'pointer',
                },
                on: {
                  click: () => {
                    this.addclient('编辑伊芯客户', params.row)

                  }
                }
              }, '编辑'),
              h('span', {

                style: {
                  marginLeft: '10px',
                  color: 'red',
                  cursor: 'pointer',

                },
                on: {
                  click: () => {
                    this.delcreate(params.row)
                  }
                }
              }, '删除')
            ]);
          }
        },
      ],
      data: {

      }
    };
  },

  mounted() {
    this.showLoading()
    this.customerlist()
    this.storeLst()


  },

  methods: {
    // fenye
    pageChange(data) {
      this.showLoading()

      this.customer.page = data
      this.customerlist()

    },
    // 导出
    async exportToExcel() {
      this.showLoading()

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');
      //添加表头
      let data = []
      for (let i = 1; i < this.columns1.length - 1; i++) {
        data.push(this.columns1[i].title)
      }
      worksheet.addRow([...data]);
      // 添加数据
      let dat = Object.assign({}, this.customer);
      let list = []
      for (let i = this.customer.page; i <= this.data.total; i++) {
        dat.page = i
        const res = await this.$api.customerlist(dat)
        list.push(...res.data.data.data);
      }
      list.map((item, index) => {
        const rowData = [item.name, item.phone, item.store_name, item.order_num, item.order_amount,];
        worksheet.addRow(rowData);
      })
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = '伊芯客户列表.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      this.hideLoading()

    }
    ,
    // 搜索
    search(value) {
      this.showLoading()
      this.customer.searchValue = value
      this.customer.page = 1
      this.customerlist()
    },
    // 门店选择
    store() {
      this.showLoading()
      this.customer.page = 1
      this.customerlist()

    },
    Select(data) {
      console.log(data);
      this.formCustom.storeName = data.label

    },
    addclient(data, item) {
      this.Modalname = data
      if (data == '编辑伊芯客户') {
        this.editId = item.id
        this.formCustom = {
          name: item.name, //姓名
          phone: item.phone, // 电话
          storeId: item.store_id,//部门id
          storeName: item.store_name,//部门名称
          orderNum: item.order_num,//订单编号
          orderAmount: item.order_amount,//订单金额
        }

      } else {
        //  添加客户


      }
      this.ModalStatus = true
    },
    // 保存  =》 添加  编辑
    cancel(data) {


      if (data) {
        if (this.Modalname == '编辑伊芯客户') {
          let data = { ...this.editId, ...this.formCustom }
          data.id = this.editId
          this.newcreate('update', data)

        } else {
          this.newcreate('newcreate', this.formCustom)
        }

      } else {
        // 清空表单
        this.$refs.formCustom.resetFields()
      }
    },
    // 添加 编辑接口
    newcreate(type, data) {
      return
      this.$refs.formCustom.validate((res) => {

        if (res) {
          this.$api[type](data).then((res) => {
            if (res.data.code == 200) {
              this.customerlist()
              this.$Message.success(res.data.msg)
            } else {
              this.$Message.error(res.data.msg)

            }
            this.$refs.formCustom.resetFields()

          }).catch((err) => { })
        }
        else {
          this.$Message.error('请检查表单完整性！')
          this.$refs.formCustom.resetFields()
        }
      })

    },
    // 删除客户
    delcreate(data) {
      this.$confirm('此操作将永久删除, 是否继续?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$api.$utils('delcreate', data.id, (data) => {
          this.customerlist()
        }, true)

      }).catch(() => {

      });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Message.success('Success!');
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    // 门店列表
    storeLst() {
      this.$api.$utils('storeLst', '', (data) => {
        this.cityList = data.data
      },)
    },
    // 客户列表
    customerlist() {


      this.$api.customerlist(this.customer).then((res) => {
        if (res.data.code == 200) {
          this.data = res.data.data
        }
        this.hideLoading()
      }).catch((err) => {

      })
    }

  },
};
</script>

<style lang="scss" scoped>
.customerList {
  padding: 20px 16px;
  background-color: #ffff;
  width: 100%;
  height: 100%;
  position: relative;

  &>:first-child {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  &>:last-child {
    margin-top: 20px;
   
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    right: 10px;
    bottom: 0;

  }
}

.form {
  // display: flex;
  // justify-content: center;
  width: 400px;
  // height: 200px;
  // background: red;
  margin: 0 auto;
}
</style>