import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: '伊芯客户管理系统-登录'
    }

  },
  {
    path: '',
    name: 'layout',
    component: () => import('../views/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
          title: '伊芯客户管理系统-客户列表',
          name:'1-1'
        }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/applyList',
        name: 'applyList',
        component: () => import('../views/applyList/index.vue'),
        meta: {
          title: '伊芯客户管理系统-申请列表',
          name:'1-2'

        }
      },
      {
        path: '/purview',
        name: 'purview',
        component: () => import('../views/purview/index.vue'),
        meta: {
          title: '伊芯客户管理系统-权限配置'
        }
      },
      {
        path: '/Equity',
        name: 'Equity',
        component: () => import('../views/Equity/index.vue'),
        meta: {
          title: '伊芯客户管理系统-权益设置',
          name:'1-3'

        }
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const token = sessionStorage.getItem('ExinToken');
  if (to.path === '/login' && token) {
    next('/');
  } else if (to.path === '/login') {
    next();
  } else {
    if (!token) {
      next('/login');
    } else {
      next();
    }
  }

});
export default router
