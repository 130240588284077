
// apiPlugin.js
import http from './http';
import Vue from 'vue';
export default {
    install(Vue) {
        Vue.prototype.$api = {

            // 登录
            LoginInterface(data) {
                return http.post('/api/admin/login', data)

            },
            purviewCreate(data) {
                return http.post('/api/admin/permission/create', data)
            },
            purviewlist(data) {
                return http.get('/api/admin/permission/lst')
            },
            // 客户列表
            customerlist(data) {

                return http.post('/api/admin/user/lst',data)
            },
            // 门店列表
            storeLst(data) {
                return http.get('/api/admin/storeLst')
            },
            // 添加客户
            newcreate(data) {
                return http.post('/api/admin/user/create', data)
            },
            // 删除客户
            delcreate(data) {
                return http.get('/api/admin/user/del?id=' + data)
            },
            // 编辑客户
            update(data) {
                return http.post('/api/admin/user/update', data)
            },
            // 申请列表
            processList(data) {
                return http.get(`/api/admin/apply/lst?status=${data.status}&limit=${data.limit}&page=${data.page}`)
            },
            // 审批
            updateStatus(data) {
                return http.post(`/api/admin/apply/updateStatus`,data)
            },
            // 上传
            uploadImg(data){
                return http.post(`/api/admin/uploadImg`,data)
            },
            // 审批
            updatequanyi(data) {
                return http.post(`/api/admin/equity/update`,data)
            },
            // 申请详情
            info(data) {
                return http.get(`/api/admin/apply/info?id=${data}`)
            },

            $utils(method, parameter, Res, msg, fn) {
                if (fn) fn()
                Vue.prototype.$api[method](parameter).then((res) => {
                    if (res.data.code == 200) {
                        Res(res.data)
                        if (msg) Vue.prototype.$Message.success(res.data.msg)
                    } else {
                        Vue.prototype.$Message.error(res.data.msg)
                    }
                }).catch((err) => {

                })
            },
        };
    },
};
