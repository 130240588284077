// mixins/loading.js

import { Loading } from 'element-ui';

export default {
  data() {
    return {
      loadingInstance: null,
    };
  },
  methods: {
    showLoading(type, customTarget) {
      const target = '.page-content';

      this.loadingInstance = Loading.service({
        fullscreen: false,
        lock: true,
        text: '加载中...',
        background: type ? 'rgba(0, 0, 0,0.5)' : 'rgba(225, 225, 225,)',
        target: target,
      });
    },

    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
  },
};
